<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <div fill-height class="mt-6 ml-6 mr-6">
    <the-nerve-table
      id="iiotActiveRemoteConnectionsList"
      :columns="columns"
      :params="params"
      :empty-state="$t('activeRemoteConnections.list.emptyList')"
      :is-action-menu-enabled="false"
      :is-check-box-column-without-select-all-enabled="canAccess('UI_REMOTE_CONNECTIONS:TERMINATE')"
      :is-row-clickable="false"
      item-key="_id"
      store-module="active-remote-connections"
      check-item-action="select_connection"
      selected-items-getter="getSelectedConnections"
      :class="{ 'wrapper-class': rcList.length >= 1 }"
      @params-changed="updateParams"
    >
      <template #additional-actions>
        <v-row>
          <v-col cols="6" lg="3" md="4" sm="5">
            <v-select
              id="iiotActiveRemoteConnectionsSelectType"
              ref="select"
              v-model="params.remoteConnectionType"
              :items="[
                { value: 'ALL', text: $t('activeRemoteConnections.list.all') },
                { value: 'tunnel', text: $t('activeRemoteConnections.list.tunnel') },
                { value: 'screen', text: $t('activeRemoteConnections.list.screen') },
              ]"
              :item-text="types.text"
              :item-value="types.value"
              :placeholder="$t('activeRemoteConnections.list.connectionType')"
              class="type-select"
              prepend-icon="mdi-filter-outline"
              attach
              @change="filterBy()"
            />
          </v-col>
        </v-row>
      </template>
      <template v-if="canAccess('UI_REMOTE_CONNECTIONS:TERMINATE')" #additional-info>
        <div class="mt-8">
          <v-row>
            <div class="mr-10 pl-5">
              <v-checkbox
                id="iiotActiveRemoteConnectionSelectAllCheckbox"
                v-model="selectAll"
                data-cy="iiotActiveRemoteConnectionSelectAllCheckbox"
                :label="$t('activeRemoteConnections.selectAll')"
                :disabled="rcList.length === 0"
                @click="selectAllOrUnselectAll"
              />
            </div>
            <div>
              <nerve-button
                id="iiotActiveRemoteConnectionRemoveButton"
                :text="$t('activeRemoteConnections.btnRemove')"
                type-of-btn="active"
                size="normal"
                :disabled="rcSelectedList.length === 0 || rcList.length === 0"
                data-cy="iiotActiveRemoteConnectionRemoveButton"
                @click-event="removeRCs"
              />
            </div>
          </v-row>
        </div>
      </template>
    </the-nerve-table>
  </div>
</template>

<script>
import { TheNerveTable, NerveButton } from 'nerve-ui-components';
import Logger from '@/utils/logger';

export default {
  components: { TheNerveTable, NerveButton },
  props: {
    page: {
      type: Number,
    },
    itemsPerPage: {
      type: Number,
    },
    search: {
      type: String,
    },
    sortBy: {
      type: Array,
    },
    sortDesc: {
      type: Array,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      types: [
        { value: 'ALL', text: this.$t('activeRemoteConnections.list.all') },
        { value: 'tunnel', text: this.$t('activeRemoteConnections.list.tunnel') },
        { value: 'screen', text: this.$t('activeRemoteConnections.list.screen') },
      ],
      params: {
        remoteConnectionType: '',
      },
      selectAll: false,
    };
  },
  computed: {
    columns() {
      return [
        {
          text: this.$t('activeRemoteConnections.list.connectionName'),
          value: 'connection',
          sortable: 'connection',
          component: {
            sfc: () => import('@/components/TableContentAsLink.vue'),
            props: {
              linkKey: 'connection',
              isLinkAllowed: true,
              queryParams: this.params,
              selectedAll: this.selectAll,
            },
          },
        },
        {
          text: this.$t('activeRemoteConnections.list.created'),
          value: 'created',
          sortable: 'created',
          width: '200px',
        },
        {
          text: this.$t('activeRemoteConnections.list.nodeName'),
          value: 'node',
          sortable: 'node',
          component: {
            sfc: () => import('@/components/TableContentAsLink.vue'),
            props: {
              linkKey: 'node',
              isLinkAllowed: this.canAccess('UI_NODE_TREE:NODE_DETAILS'),
              queryParams: this.params,
              selectedAll: this.selectAll,
            },
          },
        },
        {
          text: this.$t('activeRemoteConnections.list.serialNumber'),
          value: 'serialNumber',
          sortable: 'serialNumber',
        },
        {
          text: this.$t('activeRemoteConnections.list.tunnelScreenType'),
          value: 'tunnelPort_screenType',
          sortable: false,
        },
        {
          text: this.$t('activeRemoteConnections.list.acknowledgment'),
          value: 'acknowledgment',
          sortable: 'acknowledgment',
        },
        {
          text: this.$t('activeRemoteConnections.list.target'),
          value: 'target',
          sortable: 'target',
          component: {
            sfc: () => import('@/components/TableContentAsLink.vue'),
            props: {
              linkKey: 'target',
              isLinkAllowed: true,
              queryParams: this.params,
              selectedAll: this.selectAll,
            },
          },
        },
        {
          text: this.$t('activeRemoteConnections.list.user'),
          value: 'user',
          sortable: 'user',
          component: {
            sfc: () => import('@/components/TableContentAsLink.vue'),
            props: {
              linkKey: 'user',
              isLinkAllowed: this.canAccess('UI_USER:VIEW'),
              queryParams: this.params,
              selectedAll: this.selectAll,
            },
          },
        },
        {
          text: '',
          value: 'status',
          component: {
            sfc: () => import('@/components/active-remote-connections/NodeStatus.vue'),
          },
          sortable: false,
        },
      ];
    },

    rcList() {
      return this.$store.getters['active-remote-connections/list'];
    },

    rcSelectedList() {
      return this.$store.getters['active-remote-connections/getSelectedConnections'];
    },
  },

  watch: {
    rcList() {
      this.isSelectedAll();
    },

    rcSelectedList() {
      this.isSelectedAll();
    },
  },
  created() {
    this.params = {
      page: this.page || 1,
      itemsPerPage: this.itemsPerPage || 10,
      search: this.search || '',
      sortBy: this.sortBy || [],
      sortDesc: this.sortDesc || [],
      remoteConnectionType: this.type || '',
    };
    this.selectAll = false;
    this.$store.dispatch('active-remote-connections/select_all_connections', this.selectAll);
    this.$store.dispatch('workloads/set_selected_deployed_workload_version_timestamp', { dateString: null });
  },

  methods: {
    updateParams(parameters) {
      this.params = {
        ...parameters,
        remoteConnectionType: this.params.remoteConnectionType,
      };
      this.$router
        .push({
          name: 'Active Remote Connections',
          query: {
            page: parameters.page,
            itemsPerPage: parameters.itemsPerPage,
            search: parameters.search || undefined,
            sortBy: parameters.sortBy ? parameters.sortBy[0] : [],
            sortDesc: parameters.sortDesc ? parameters.sortDesc[0] : [],
            type: parameters.remoteConnectionType || undefined,
          },
        })
        .catch(() => {});
    },
    async filterBy(action) {
      try {
        if (action === 'delete') {
          await this.$store.dispatch('active-remote-connections/fetch', this.params);
        } else {
          this.params.page = 1;
          await this.$store.dispatch('active-remote-connections/fetch', this.params);
        }
      } catch (err) {
        Logger.error(err);
      }
    },

    selectAllOrUnselectAll() {
      this.$store.dispatch('active-remote-connections/select_all_connections', this.selectAll);
    },

    async removeRCs() {
      this.$store.dispatch('utils/_api_request_handler/show_confirm_dialog', {
        title: 'activeRemoteConnections.list.terminateTitle',
        subTitle: 'activeRemoteConnections.list.terminateSubTitle',
        callback: async () => {
          try {
            this.$store.dispatch('utils/_api_request_handler/show_loading_bar');
            await this.$store.dispatch('active-remote-connections/terminate_connections');

            this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
              text: 'activeRemoteConnections.list.successfullyTerminateRemoteConnection',
              color: 'success',
              showClose: true,
            });
            this.filterBy('delete');
          } catch (err) {
            Logger.error(err);
          } finally {
            this.$store.dispatch('utils/_api_request_handler/close_loading_bar');
          }
        },
      });
    },

    isSelectedAll() {
      if (this.rcSelectedList.length === 0 || this.rcList.length === 0) {
        this.selectAll = false;
        return;
      }

      this.selectAll = this.rcList.every(
        (rc) => !!this.rcSelectedList.find((selectedRc) => rc.requestUid === selectedRc.requestUid),
      );
    },
  },
};
</script>

<style lang="scss">
#iiotActiveRemoteConnectionsSelectType::placeholder {
  font-size: 14px !important;
}
.wrapper-class {
  .v-data-table__wrapper {
    padding-left: 43px;
  }
  #iiotActiveRemoteConnectionsList > .v-data-table__wrapper > table > tbody tr td {
    max-width: 150px !important;
  }
  #iiotActiveRemoteConnectionsList > .v-data-table__wrapper > table > thead > tr > th {
    white-space: normal !important;
  }
}
</style>
